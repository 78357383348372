import axios from '@/plugins/axios'

const state = () => ({
  description: null,
  id: null,
  isAccepted: null

})

const mutations = {
  setTerms: (state, terms) => {
    for (let key in terms) {
      state[key] = terms[key]
    }
  }
}

const actions = {
  fetchTerms: async context => {
    let url = '/api/terms'
    let response = await axios.get(url)
    let terms = response.data
    context.commit('setTerms', terms)
  },
  acceptTerms: async (context, id) => {
    let url = '/api/terms/' + id
    return axios.put(url)
  },
  fetchPublibTerms: async context => {
    let url = '/api/terms'
    let response = await axios.get(url)
    let terms = response.data.description
    return terms
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
