import Vue from 'vue'
import App from './app'
import router from './router'
import store from './store'
import elements from './elements'
import VueBarcode from 'vue-barcode'
import { Datetime } from 'vue-datetime'

import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

Vue.use(VueBarcode)

Vue.mixin({
  methods: {
    config (key) {
      return {
        'feature_user_points': process.env.VUE_APP_FEATURES__USER_POINTS === 'true' || process.env.VUE_APP_FEATURES__USER_POINTS === 'True' || process.env.VUE_APP_FEATURES__USER_POINTS === 'TRUE',
        'feature_receipt_ocr': process.env.VUE_APP_FEATURES__RECEIPT_OCR === 'true' || process.env.VUE_APP_FEATURES__RECEIPT_OCR === 'True' || process.env.VUE_APP_FEATURES__RECEIPT_OCR === 'TRUE',
        'feature_points_redemption': process.env.VUE_APP_FEATURES__POINTS_REDEMPTION === 'true' || process.env.VUE_APP_FEATURES__POINTS_REDEMPTION === 'True' || process.env.VUE_APP_FEATURES__POINTS_REDEMPTION === 'TRUE'
      }[key]
    }
  }
})

new Vue({
  router,
  store,
  elements,
  render: h => h(App)
}).$mount('#app')
