import axios from '@/plugins/axios'

const actions = {
  async createScanner (context) {
    let url = '/api/scanners'
    let response = await axios.post(url)
    return response.data
  },

  async uploadScannerImage (context, { scannerId, image }) {
    let url = `/api/scanners/${scannerId}`
    let data = { image }
    let response = await axios.post(url, data)
    return response.data
  },

  async createReceipt (context, data) {
    let url = '/api/receipts'
    let response = await axios.post(url, data)
    return response.data
  }
}

export default {
  namespaced: true,
  actions
}
