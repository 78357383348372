import axios from '@/plugins/axios'

const state = () => ({
  profile: { registered: false },
  coupons: null,
  photos: null,
  redemptions: null,
  receipts: null
})

const mutations = {
  setProfile: (state, profile) => {
    state.profile = profile
  },

  setCoupons: (state, coupons) => {
    state.coupons = coupons
  },

  setPhotos: (state, photos) => {
    state.photos = photos
  },

  setRedemptions: (state, redemptions) => {
    state.redemptions = redemptions
  },

  setReceipts: (state, receipts) => {
    state.receipts = receipts
  }
}

const actions = {
  fetchProfile: async context => {
    let url = '/api/me'
    let response = ''
    let profile = {}
    try {
      response = await axios.get(url)
      profile = response.data
      profile['registered'] = true
    } catch (error) {
      if (error.response.status === 404) {
        profile['registered'] = false
      }
    }
    context.commit('setProfile', profile)
  },

  updateProfile: async (context, data) => {
    let url = 'api/me'
    let response = ''
    let profile = {}
    try {
      response = await axios.put(url, data)
      profile = response.data
      profile['registered'] = true
    } catch (error) {
      if (error.response.status === 404) {
        profile['registered'] = false
      }
    }
    context.commit('setProfile', profile)
  },

  fetchCoupons: async context => {
    let url = '/api/me/coupons'
    let response = await axios.get(url)
    let coupons = response.data
    context.commit('setCoupons', coupons)
  },

  useCoupon: (context, couponId) => {
    let url = `/api/me/coupons/${couponId}`
    let response = axios.put(url)
    return response
  },

  fetchReceipt: async context => {
    let url = 'api/me/receipts'
    let response = await axios.get(url)
    let receipts = response.data
    context.commit('setReceipts', receipts)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
