import axios from 'axios'

const actions = {
  async stitchImage (context, data) {
    let url = 'https://abc-stitch-dev.wi.th/stitch'
    let response = await axios.post(url, data, {
      responseType: 'blob'
    })

    const file = new File([response.data], 'foo.png', {
      type: 'image/png'
    })

    return file
  }
}

export default {
  namespaced: true,
  actions
}
