import Vue from 'vue'
import Dialog from './dialog'
import Select from './select'

const elements = {
  'dialog-element': Dialog,
  'select-element': Select
}

Object.keys(elements).forEach(name => {
  Vue.component(name, elements[name])
})

export default {}
