import axios from '@/plugins/axios'

const state = () => ({
  winner: null,
  publicwinners: null
})

const mutations = {

  setWinner: (state, winners) => {
    state.winner = winners
  },
  setPublibWinners: (state, winners) => {
    state.publicwinners = winners
  }
}

const actions = {

  fetchWinners: async (context, dates) => {
    let url = '/api/winners'
    let response = await axios.get(url, {
      params: {
        date: dates
      }
    })
    let winnerList = response.data
    winnerList.forEach(item => {
      let slicedNumber = item.phoneNumber.slice(0, 7)
      let maskedPNumber = slicedNumber + 'XXX'
      item['maskedPhoneNumber'] = maskedPNumber
    })
    context.commit('setWinner', winnerList)
  },
  fetchPublicWinners: async (context, dates) => {
    let url = '/api/winners'
    let response = await axios.get(url, {
      params: {
        date: dates
      }
    })
    let winnerList = response.data
    winnerList.forEach(item => {
      let slicedNumber = item.phoneNumber.slice(0, 7)
      let maskedPNumber = slicedNumber + 'XXX'
      item['maskedPhoneNumber'] = maskedPNumber
    })
    context.commit('setPublibWinners', winnerList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
