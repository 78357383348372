import axios from '@/plugins/axios'

const state = () => ({
  name: null,
  images: null,
  pointsRatio: null,
  startDate: null,
  endDate: null,
  products: null
})
const mutations = {
  setCampaign: (state, data) => {
    for (let key in data) {
      state[key] = data[key]
    }
  },
  setProducts: (state, data) => {
    state.products = data
  }
}
const actions = {
  fetchCampaign: async context => {
    let url = '/api/campaign'
    let campaign = await axios.get(url)
    context.commit('setCampaign', campaign.data)
  },
  fetchProducts: async (context) => {
    let url = '/api/campaign/products'
    let response = await axios.get(url)
    context.commit('setProducts', response.data.products)
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state
}
