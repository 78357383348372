<template>
  <div class='select'>
    <div class='sel-l'
      v-if='label'>
      {{ label }}
    </div>
    <select class='sel-s'
      required
      :value='value'
      :disabled='disabled'
      @change='$emit("input", $event.target.value)'>
      <option
        selected
        value=''
        v-if='placeholder'>
        {{ placeholder }}
      </option>
      <slot/>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      placeholder: String,
      value: String,
      disabled: Boolean
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .sel-l {
    margin: 0 0 4px 4px;
    color: map.get(variables.$colors, gray, 700);
    font-weight: map.get(variables.$font-weights, medium);
    font-size: map.get(variables.$font-sizes, s);
  }

  .sel-s {
    padding: 2px 4px 2px 4px;
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
    background-color: map.get(variables.$colors, white);
    border-radius: 8px;
    height: 34px;
    font-size: map.get(variables.$font-sizes, m);

    &:invalid {
      color: map.get(variables.$colors, gray, 500);
    }

    &:disabled {
      color: map.get(variables.$colors, gray, 500);
      background-color: map.get(variables.$colors, gray, 300);
    }
  }
</style>
