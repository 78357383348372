import axios from '@/plugins/axios'

const actions = {
  scan: async (context, { data }) => {
    let url = '/api/scanners'
    return await axios.post(url, data)
  }
}

export default {
  namespaced: true,
  actions
}
