<template>
  <div class='dialog'>
    <transition name='dia-c'>
      <div class='dia-c'
        v-if='visible'>
        <div class='dia-t'>
          <slot/>
        </div>
        <div>
          <slot name='actions'/>
        </div>
      </div>
    </transition>
    <transition name='dia-d'>
      <div class='dia-d'
        v-if='visible'>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    data: () => ({
      visible: false
    }),

    destroyed () {
      this.close()
    },

    methods: {
      open () {
        if (this.visible) return
        this.visible = true
        document.body.style.overflowY = 'hidden'
      },

      close () {
        if (!this.visible) return
        this.visible = false
        document.body.style.overflowY = ''
      }
    }
  }
</script>

<style lang='scss' scoped>
  @import '~@/styles/variables';

  .dialog {
    position: absolute;
  }

  .dia-c {
    position: fixed;
    top: 10%;
    right: 16px;
    left: 16px;
    z-index: map-get($elevations, dialog);
    display: flex;
    flex-direction: column;
    margin: auto;
    max-height: 70%;
    background-color: white;
    border-radius: 8px;

    &-enter-active,
    &-leave-active {
      transition: all 0.2s ease-in-out;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: scale(1.025);
    }
  }

  .dia-t {
    flex-grow: 1;
  }

  .dia-d {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: map-get($elevations, dialog) - 1;
    background-color: rgba(0, 0, 0, 0.6);

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease-in-out;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
</style>
