import axios from '@/plugins/axios'

const actions = {
  getToken: async (context, data) => {
    let url = '/api/auth/token'
    let token = await axios.post(url, data)
    return token
  }
}

export default {
  namespaced: true,
  actions
}
