import Vue from 'vue'
import Vuex from 'vuex'
import me from './modules/me'
import users from './modules/users'
import receipt from './modules/receipt'
import winner from './modules/winner'
import auth from './modules/auth'
import terms from './modules/terms'
import campaign from './modules/campaign'
import scanner from './modules/scanner'
import upload from './modules/upload'
import stitch from './modules/stitch'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    me,
    users,
    receipt,
    winner,
    auth,
    terms,
    campaign,
    scanner,
    upload,
    stitch
  }
})
