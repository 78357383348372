import axios from '@/plugins/axios'

const actions = {
  uploadImage: async (context, image) => {
    let url = '/api/upload'
    let bodyFormData = new FormData()
    bodyFormData.append('image', image, image.name)
    return await axios.post(url, bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
}

export default {
  namespaced: true,
  actions
}
