import axios from '@/plugins/axios'

const actions = {
  register: async (context, { data }) => {
    let url = '/api/users/register'
    return await axios.post(url, data)
  }
}

export default {
  namespaced: true,
  actions
}
